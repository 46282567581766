import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import styles from './header.module.css'

import logo from '../assets/noovel-logo.png'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>


      <button aria-label="toggle menu" className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button>
      <div className={styles.mobileLogo}><Link to='/' aria-label="Home Link"><img src={logo} className={styles.logo} alt="noovel logo" /></Link></div>
      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
          <Link to='/about/'>About</Link>
          </li>
          <li>
          <Link to='/contact/'>Contact</Link>
          </li>
          <li className={styles.branding}>
            <Link to='/' aria-label="Home Link"><img src={logo} className={styles.logo} alt="noovel logo" /></Link>
          </li>
          <li>
            <Link to='/join/'>Join The Team</Link>

          </li>
          <li>
            <Link to='/merch/'>MERCH</Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
